import React from "react";
import styles from "./OldCodeContent.module.css";
export default function OldCodeContent({ selectedLesson }) {
    return (
        <div
            className={styles.container}
            dangerouslySetInnerHTML={{ __html: selectedLesson.content }}
        />
    );
}
