import React from "react";
import styles from "./Course.module.css";
function Course({ course }) {
    return (
        <div className={styles.courseContainer}>
            <div className={styles.courseBlock}>
                <img
                    className={styles.img}
                    src={course.imageUrl}
                    alt={course.title}
                />
                <h3 className={styles.title}>{course.title}</h3>
                <p className={styles.subTitle}>
                    <b>{course?.subTitle}</b>
                </p>
                <p>
                    מחיר: {course.price > 0 ? course.price + " ש״ח " : "חינם"}
                </p>
                {/* <p className={styles.discount}>85% הנחה לתלמידי תיכון</p>
                <p className={styles.discount}>
                    הקורס <span style={{ color: "red" }}>בחינם</span> בזמן
                    המלחמה 🇮🇱{" "}
                </p> */}

                <p>שיעורים: {course.numOfLessons}</p>
            </div>
        </div>
    );
}

export default Course;
