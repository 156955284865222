import React from "react";
import styles from "./LessonHeader.module.css";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
export default function LessonHeader({ courseObj, lesson, module }) {
    function download() {
        window.open(
            "https://drive.google.com/drive/folders/14cSZuUqKy8ow0R1EZ7s0ouHuMr8EFUeo?usp=sharing",
            "_blank"
        );
        return false;
    }
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.nextArrow}>
                    <FiChevronRight />
                </div>

                <div className={styles.moduleTitle}>{module.title}</div>
                <div className={styles.lessonTitle}> {lesson.title}</div>

                <div className={styles.prevArrow}>
                    <span
                        style={{
                            display: "inline-block",
                            fontSize: "20px",
                            fontWeight: "normal",
                        }}
                    >
                        הבא
                    </span>

                    <FiChevronLeft> </FiChevronLeft>
                </div>
                <div onClick={download} className={styles.download}>
                    הורד קבצי קורס
                </div>
            </div>
        </div>
    );
}
