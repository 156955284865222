import React from "react";
import styles from "./Footer.module.css";
export default function Footer() {
    const s1 = "כל הזכויות שמורות לבריינטופ בע״מ";
    const s2 = "תקנון האתר";
    const negishoot = "הצהרת נגישות";
    function openTakanon(e) {
        e.stopPropagation();
        window.open(
            "https://docs.google.com/document/d/1qGVGD4s43uJTiuJPwfqUOi9DrckHnsGUP7Zzrm-Nhak/edit?usp=sharing",
            "_blank"
        );
    }
    function openNegishoot(e) {
        e.stopPropagation();
        window.open(
            "https://docs.google.com/document/d/1PCJymRlF6V7bQdPpwlWocDv_RW2hYX9hE421uIdLgF0/edit?usp=sharing"
        );
    }
    return (
        <div className={styles.container}>
            <p>
                {s1}{" "}
                <span
                    style={{
                        borderBottom: "1px solid black",
                        cursor: "pointer",
                    }}
                    onClick={openTakanon}
                >
                    {s2}
                </span>{" "}
                <span> </span>
                <span
                    style={{
                        borderBottom: "1px solid black",
                        cursor: "pointer",
                    }}
                    onClick={openNegishoot}
                >
                    {negishoot}
                </span>
            </p>
        </div>
    );
}
