import React, { useState } from "react";
import { FiChevronUp, FiChevronDown } from "react-icons/fi";
import styles from "./Accordion.module.css"; // Import your CSS Module file with styles
const AccordionItem = ({ children, title, subTitle, index, content }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={`${styles.accordionItem} ${isOpen ? styles.open : ""}`}>
            <div className={styles.accordionHeader} onClick={toggleAccordion}>
                <div className={styles.box}>
                    <div className={styles.rightItem}>
                        <span
                            className={`${styles.accordionIcon} ${
                                isOpen ? styles.open : ""
                            }`}
                        ></span>
                        {isOpen ? <FiChevronDown /> : <FiChevronUp />}
                        <div className={styles.index}>{index}</div>
                        <div className={styles.title1}>{title}</div>
                    </div>
                    <div className={styles.leftItems}>
                        <div>{subTitle}</div>
                    </div>
                </div>
            </div>

            <div className={styles.accordionContent}>
                {isOpen && <div className={styles.content}>{children}</div>}
            </div>
        </div>
    );
};

const Accordion = ({ items }) => {
    return items?.map((item, index) => {
        return (
            <div key={item.id} className={styles.accordion}>
                <AccordionItem
                    index={index}
                    title={item?.title}
                    subtitle={item?.category}
                ></AccordionItem>
            </div>
        );
    });
};

export default Accordion;
