import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import styles from "./HeaderMobile.module.css";
import { GiHamburgerMenu } from "react-icons/gi";
import { auth } from "../../services/FirebaseConfig";
const HeaderMobile = ({ onLogout }) => {
    const [showNavbar, setShowNavbar] = useState(false);

    const handleShowNavbar = () => {
        setShowNavbar(!showNavbar);
    };
    function logout() {
        onLogout();
        return;
    }

    const navigate = useNavigate();
    function logout() {
        onLogout();
        return;
    }
    const arr = [
        {
            href: auth?.currentUser?.email ? "/logout" : "/login",
            title: auth?.currentUser?.email ? "התנתקות" : "התחברות",
            type: "text",
            order: 0,
        },
        {
            href: "/",
            title: <img src="/logo.png" alt="logo" />,
            order: 1,
            type: "logo",
        },
        {
            href: "/",
            type: "text",
            title: "קורסים 🔥",
            order: 2,
        },
        {
            href: "/books",
            title: "ספרים 📚",
            type: "text",
            order: 3,
        },
        {
            href: "/contact",
            title: "צור קשר 😊",
            type: "text",
            order: 4,
        },
        {
            href: "/about",
            type: "text",
            title: "אודות 🤙",
            order: 5,
        },
        {
            href: "/together",
            type: "text",
            title: "❤️ יחד ננצח 🇮🇱",
            order: 6,
        },
    ];

    return (
        <nav className={styles.navbar}>
            <div className={styles.container}>
                <NavLink className={styles.logout} onClick={logout}>
                    {arr[0].title}
                </NavLink>
                <div className={styles.logo}>
                    <NavLink to="/">
                        <img src="/logo.png" alt="logo" />
                    </NavLink>
                </div>
                <div className={styles.menuIcon} onClick={handleShowNavbar}>
                    <GiHamburgerMenu />
                </div>
                <div
                    className={`${styles.navElements} ${
                        showNavbar ? styles.active : ""
                    }`}
                >
                    <ul>
                        {arr.map((item) => {
                            return (
                                item.order > 1 && (
                                    <li key={item.href}>
                                        <NavLink
                                            to={item.href}
                                            onClick={handleShowNavbar}
                                        >
                                            {item.title}
                                        </NavLink>
                                    </li>
                                )
                            );
                        })}
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default HeaderMobile;
