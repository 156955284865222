import React from "react";
import styles from "./Login.module.css"; // Import css modules stylesheet as styles
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

import FirebaseAuthService from "../../../services/FirebaseAuthService";
import { showNotification } from "../../../util/notification";
import { auth } from "../../../services/FirebaseConfig";
import { sendPasswordResetEmail } from "@firebase/auth";

export default function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const navigate = useNavigate();
    function onEmailChange(e) {
        e.preventDefault();
        setEmail(e.target.value);
    }
    function onPasswordChange(e) {
        e.preventDefault();
        setPassword(e.target.value);
    }
    async function resetPassword() {
        if (email.length === 0) {
            alert("אנא מלא את שדה המייל באימייל חוקי");
            return;
        } else {
            try {
                await sendPasswordResetEmail(auth, email); // Use sendPasswordResetEmail function
                showNotification("מצוין", "נשלחה לך סיסמא לאיפוס האימייל");
            } catch (error) {
                showNotification(
                    "error",
                    "תקלה בשליחת האימייל נסה שוב: " + error.message
                );
            }
        }
    }
    async function loginUser(event) {
        try {
            await FirebaseAuthService.loginUser(email, password);
            setEmail("");
            setPassword("");
            showNotification("מצוין", email + " כניסה למערכת הצליחה");
            navigate("/");
        } catch (err) {
            showNotification("error", err.message);
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.title}>לוגאין</div>
            <p>ברוכים הבאים! </p>
            <div className={styles.inputs}>
                <input
                    type="email"
                    className={styles.inputText}
                    placeholder="אימייל"
                    onChange={(e) => onEmailChange(e)}
                />
                <input
                    type="password"
                    className={styles.inputText}
                    placeholder="סיסמא"
                    onChange={(e) => onPasswordChange(e)}
                />
                <button className={styles.btnRegister} onClick={loginUser}>
                    לוגאין
                </button>
            </div>
            <div>
                <p style={{ textAlign: "center" }}>
                    שכחתי סיסמא?
                    <Link to="/resetPassword" onClick={resetPassword}>
                        שכחתה סיסמא
                    </Link>
                </p>
                <p style={{ textAlign: "center" }}>
                     אין לך חשבון <Link to="/register">הרשם</Link>
                </p>
            </div>
        </div>
    );
}
