import React from "react";
import BookPdf from "../Content/LearnCourse/BookPdf/BookPdf";
import styles from "./Books.module.css";
import { androidBooks } from "../Content/Books/android";
import { xamerinBooks } from "../Content/Books/android";

export default function Books() {
    return (
        <div className={styles.container}>
            <div className={styles.pdfBook}>
                <BookPdf books={androidBooks} title={"ספרי אנדרואיד סטודיו"} />
            </div>
            <hr style={{ width: "100%" }}></hr>
            <div className={styles.pdfBook}>
                <BookPdf books={xamerinBooks} title={"ספרי אנדרואיד זמרין"} />
            </div>
        </div>
    );
}
