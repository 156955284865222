import React, { useState, useEffect } from "react";
import { AiOutlineReload } from "react-icons/ai";
import styles from "./Loader.module.css"; // Path to your CSS file

export default function Loader() {
    return (
        <div>
            <AiOutlineReload className={styles.loaderIcon} />
        </div>
    );
}
