import { createContext, useContext, useEffect, useState } from "react";
import FirebaseFirestoreRestService from "../../../services/FirebaseFireStoreRestService";

const CourseContext = createContext();

export default function CourseProvider({ children }) {
    const [isLoading, setIsLoading] = useState(false);
    function getId() {
        const urlPath = window.location.pathname;
        const parts = urlPath.split("/");
        if (parts && parts.length > 1) {
            if (parts[1] === "course") {
                const id = parts[parts.length - 1];
                return id;
            }
        } else return null;
    }
    const [courseObj, setCourseObj] = useState({
        order: 0,
        title: "",
        goal: "",
        category: "",
        programingLanguage: "",
        publishDate: "",
        duration: "",
        price: "",
        slug: "",
        isOnline: true,
        imageUrl: "",
        //second column
        author: "",
        github: "",
        level: "",
        type: "",
        isActive: true,
        rating: "",
        quantityLesson: "",
        created: "",
        modified: "",
        //video tab
        videoYouTubeUrl: "",
        videoVimeoUrl: "", //lesson tab
        //module tab
        tags: [],
        sylabus: [],
        courseModules: [],
        whatYouLearn: [],
        forWhoIsThisCourse: [],
        requierments: [],
    });
    const id = getId();

    useEffect(() => {
        async function getCourseById() {
            const course = await FirebaseFirestoreRestService.readDocumentsById(
                "courses",
                id
            );
            setCourseObj(course.course);
        }
        if (id) getCourseById();
    }, [id]);

    return (
        <CourseContext.Provider value={{ courseObj, isLoading, setIsLoading }}>
            {children}
        </CourseContext.Provider>
    );
}
function useCourse() {
    const context = useContext(CourseContext);
    if (context === undefined) {
        throw new Error("error oocered fetched course");
    }
    return context;
}

export { CourseContext, useCourse };
