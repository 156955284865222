import { Route, Routes, useNavigate } from "react-router-dom";
import Courses from "./components/Content/Courses/Courses";
import Register from "./components/Users/Register/Register";
import { Toaster } from "react-hot-toast";
import Login from "./components/Users/Login/Login";
import { auth } from "./services/FirebaseConfig";
import FirebaseAuthService from "./services/FirebaseAuthService";
import Footer from "./components/Footer/Footer";
import HeaderMobile from "./components/Header/HeaderMobile";
import AboutCourse from "./components/Content/AboutCourse/AboutCourse";
import CourseProvider from "./components/Content/contexts/CourseContext";
import LearnCourse from "./components/Content/LearnCourse/LearnCourse";
import Contact from "./components/Contact/Contact";
import About from "./components/About/About";
import styles from "./App.module.css";
import Books from "./components/Books/Books";
function App() {
    const navigate = useNavigate();
    async function f() {
        const token = await auth.currentUser.getIdToken();
        console.log(token);
    }
    async function logout() {
        await FirebaseAuthService.logout();
        navigate("/login");
    }

    return (
        <div className={styles.app}>
            {/*<ShowFullPdf height={500} width={400} />*/}
            {/* <button onClick={f}>click</button> */}
            <div className={styles.header}>
                <HeaderMobile onLogout={logout} />
            </div>
            <div className={styles.main}>
                <CourseProvider>
                    <Routes>
                        <Route path="/course/:id" element={<AboutCourse />} />
                        <Route
                            path="/course/learn/:id"
                            element={<LearnCourse />}
                        />
                    </Routes>
                </CourseProvider>

                <Routes>
                    <Route path="/" element={<Courses />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/resetPassword" element={<Login />} />
                    <Route path="/books" element={<Books />} />
                </Routes>
            </div>
            <footer className={styles.footer}>
                <Footer />
            </footer>
        </div>
    );
}
export default App;
