import React from "react";
import styles from "./WhatWeLearn.module.css";
import { useNavigate, useParams } from "react-router-dom";
export default function WhatWeLearn({ course }) {
    const { id } = useParams();
    const navigate = useNavigate();
    function startLearn() {
        navigate("/course/learn/" + id);
    }

    return (
        <div className={styles.container}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                }}
            >
                <h1>מה נלמד בקורס זה</h1>
                <button onClick={startLearn} className={styles.joinNow}>
                    התחל ללמוד
                </button>
            </div>
            <p className={styles.about}>{course.about}</p>
            <p className={styles.about}>בנוסף נלמד</p>
            <ul>
                {course.whatYouLearn.map((item, index) => {
                    return (
                        <li key={index} className={styles.li}>
                            {item}
                        </li>
                    );
                })}
            </ul>
            <p className={styles.about}>{course.aboutEnd}</p>
        </div>
    );
}
