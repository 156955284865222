import React from "react";
import styles from "./BookPdf.module.css";

export default function BookPdf({ books, title }) {
    const linkToBooks = books?.map((item, index) => {
        return (
            <div key={index} className={styles.pdfs}>
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    key={index}
                    href={item.href}
                    onClick={(e) => {
                        e.stopPropagation();
                        window.open(item.href, "_blank");
                        return false;
                    }}
                >
                    <img src={item.image} alt="book android" width={200} />
                    <div>{item.title}</div>
                </a>
            </div>
        );
    });
    return (
        <div>
            <h1>{title}</h1>
            <div className={styles.container}>{linkToBooks}</div>
        </div>
    );
}
