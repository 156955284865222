import React, { useEffect, useState } from "react";
import styles from "./HeaderVideo.module.css";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import OldCodeContent from "./OldCodeContent/OldCodeContent";

export default function HeaderVideo({ course, selectedLesson }) {
    // "https://player.vimeo.com/video/347666804"

    const [video, setVideo] = useState("");
    const [wh, setWh] = useState({ width: "800px", height: "450px" });

    useEffect(() => {
        function extractVimeoUrl(iframeString) {
            const regex = /src="(https:\/\/player\.vimeo\.com\/video\/\d+)"/;
            const match = iframeString.match(regex);

            return match ? match[1] : null;
        }
        if (selectedLesson.videoVimeoUrl) {
            const v = extractVimeoUrl(selectedLesson.videoVimeoUrl);
            setVideo(v);
        }
    }, [selectedLesson]);
    useEffect(() => {
        function setWidthAndHeight() {
            const viewportWidth = window.innerWidth / window.devicePixelRatio;
            // alert(
            //     "viewportWidth:" +
            //         viewportWidth +
            //         " window.innerWidth:" +
            //         window.innerWidth
            // );
            if (viewportWidth <= 420) {
                setWh({
                    width: viewportWidth * 2,
                    height: (viewportWidth * 2 * 9) / 16,
                });
            } else if (viewportWidth <= 768) {
                setWh({ width: "400", height: "225" });
            } else if (viewportWidth <= 1000) {
                setWh({ width: "800", height: "450" });
            }
        }

        setWidthAndHeight(); // Set initial size based on viewport width

        window.addEventListener("resize", setWidthAndHeight);

        return () => {
            window.removeEventListener("resize", setWidthAndHeight);
        };
    }, []);

    return (
        <div className={styles["iframe-container"]}>
            <iframe
                src={video}
                allow="fullscreen; picture-in-picture"
                title="Vimeo Video"
                frameBorder="0"
            ></iframe>
        </div>
    );
}
