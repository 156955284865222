import { addDoc, collection } from "@firebase/firestore";
import { auth, db, updateProfile } from "./FirebaseConfig";

import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
    signInWithPopup,
    GoogleAuthProvider,
    onAuthStateChanged,
} from "firebase/auth";
import { showNotification } from "../util/notification";

const registerUser = async (email, password, displayName) => {
    let result = null;
    try {
        result = await createUserWithEmailAndPassword(auth, email, password);
        await updateProfile(auth.currentUser, {
            displayName: displayName,
            //photoURL: "https://example.com/jane-q-user/profile.jpg",
            //phoneNumebr: ""
        });
        const userObj = { email, firstName: displayName, courses: [] };
        await addUser(userObj);
    } catch (error) {
        throw new Error("error.message");
    }
    return result;
};
async function addUser(userObj) {
    const userRef = collection(db, "users");
    await addDoc(userRef, userObj);
    showNotification("הצלחה", "המשתמש נוסף בהצלחה");
}

function logout() {
    return auth.signOut();
}
async function loginUser(email, password) {
    return await signInWithEmailAndPassword(auth, email, password);
}

const loginWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    return await signInWithPopup(auth, provider);
};

const subscribeToAuthChanges = (handleAuthChange) => {
    onAuthStateChanged(auth, (user) => {
        handleAuthChange(user);
    });
};

const FirebaseAuthService = {
    registerUser,
    loginUser,
    logout,
    sendPasswordResetEmail: (email) => {
        sendPasswordResetEmail(auth, email);
    },
    loginWithGoogle,
    subscribeToAuthChanges,
};

export default FirebaseAuthService;
