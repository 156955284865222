import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./AboutCourse.module.css";
import { GoVideo } from "react-icons/go";
import { BsFileEarmarkPdf } from "react-icons/bs";
import { BsPen } from "react-icons/bs";

import WhatWeLearn from "./WhatWeLearn/WhatWeLearn";
import Accordion from "./Accordion/Accordion";
import { CourseContext } from "../contexts/CourseContext";
import Loader from "../../Tools/Loader/Loader";
export default function AboutCourse() {
    const { courseObj } = useContext(CourseContext);
    const { id } = useParams();
    const navigate = useNavigate();
    function startLearn() {
        navigate("/course/learn/" + id);
    }
    return (
        <div className={styles.container}>
            {courseObj ? (
                <>
                    <div className={styles.header}>
                        <div className={styles.page_image}>
                            <img
                                className={styles.img}
                                src={courseObj?.page_image}
                                alt="android page"
                            />
                            <div className={styles.title}>
                                <h1>{courseObj?.title}</h1>
                            </div>
                            <div className={styles.subTitle}>
                                <h3>{courseObj?.subTitle}</h3>
                            </div>
                        </div>

                        <div className={styles.video}>
                            <iframe
                                style={{ borderRadius: "10px" }}
                                src={courseObj.videoLink}
                                allow="fullscreen; picture-in-picture"
                                title="Vimeo Video"
                            ></iframe>
                            <h3>בקצרה</h3>
                            <div>
                                <ol className={styles.ol}>
                                    <li>
                                        <GoVideo />
                                        <span className={styles.span}>
                                            וידאו בכל שיעור
                                        </span>
                                    </li>
                                    <li>
                                        <BsFileEarmarkPdf />
                                        <span className={styles.span}>
                                            חוברת קורס מסודרת
                                        </span>
                                    </li>
                                    <li>
                                        <BsPen />
                                        <span className={styles.span}>
                                            תרגול
                                        </span>
                                    </li>
                                </ol>
                                <button
                                    className={styles.joinNow}
                                    onClick={startLearn}
                                >
                                    התחל ללמוד
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={styles.WhatWeLearn}>
                        <WhatWeLearn course={courseObj} />
                    </div>

                    <div className={styles.accordion}>
                        <Accordion items={courseObj?.courseModules} />
                    </div>
                </>
            ) : (
                <div className={styles.loader}>
                    <Loader />
                </div>
            )}
        </div>
    );
}
