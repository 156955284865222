import React from "react";
import styles from "./About.module.css";
export default function About() {
    const txt1 =
        "בריינטופ ישראל נוסדה בשנת 2010 , ע״י אסף עמיר, מרצה בתחום התוכנה והחומרה.";
    const txt2 = "באתר שלנו תוכלו למצוא קורסים ומוצרים.";
    const txt3 = "החזון שלנו הוא לאפשר לכל אדם ואדם ללמוד ";
    const txt4 =
        "אם הנך רוצה לשלב טכנולוגיה במקום בו אתה עובד אז מקומך הוא איתנו";

    return (
        <div className={styles.container}>
            <h1 className={styles.h1}>אודות</h1>
            <p className={styles.p}>{txt1}</p>
            <p className={styles.p}>{txt2}</p>
            <p className={styles.p}>{txt3}</p>
            <p className={styles.p}>{txt4}</p>
            <div className={styles.img}>
                <img
                    style={{
                        border: "5px solid var(--blue",
                        width: "50%",
                    }}
                    src="assets/images/bgabout.png"
                    alt="about"
                />
            </div>
        </div>
    );
}
