import React from "react";
import styles from "./LessonsMenu.module.css";
import TableContent from "../TableContent/TableContent";
export default function LessonsMenu({ items, selectLesson }) {
    return (
        <div className={styles.tableOfCOntent}>
            {items?.map((item, index) => {
                return (
                    <TableContent
                        title={item.title}
                        index={index + 1}
                        subTitle={`${item.moduleLessons.length} שיעורים`}
                        key={item.id}
                    >
                        <ul className={styles.ul}>
                            {item.moduleLessons.map((lesson, indexLesson) => {
                                return (
                                    <ol
                                        onClick={(e) => {
                                            e.stopPropagation(); // Stop event propagation
                                            selectLesson(item, lesson, e);
                                        }}
                                        className={styles.ol}
                                        key={lesson.id}
                                    >
                                        שיעור {indexLesson + 1}.{lesson.title}
                                    </ol>
                                );
                            })}
                        </ul>
                    </TableContent>
                );
            })}
        </div>
    );
}
