import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./LearnCourse.module.css";
import LessonsMenu from "./SideBar/LessonsMenu/LessonsMenu";
import FirebaseFirestoreRestService from "../../../services/FirebaseFireStoreRestService";
import HeaderVideo from "./SideLeft/HeaderVideo/HeaderVideo";
import { CourseContext } from "../contexts/CourseContext";
import LessonHeader from "./LessonHeader/LessonHeader";
import OldCodeContent from "./SideLeft/HeaderVideo/OldCodeContent/OldCodeContent";
import BookPdf from "./BookPdf/BookPdf";
import Loader from "../../Tools/Loader/Loader";
import { androidBooks, xamerinBooks } from "../Books/android";

export default function LearnCourse() {
    const { courseObj } = useContext(CourseContext);
    const books =
        courseObj.id === "wmdWWTs17sLTO8N20HtO" ? androidBooks : xamerinBooks;
    const [module, setModule] = useState({});
    const [lesson, setLesson] = useState({});
    const [selectedContent, setSelectedContent] = useState("video"); // By default show the video
    const myDivRef = useRef(null);

    const buttons = [
        { label: "ראה וידאו", content: "video" },
        { label: "הורד ספר הקורס", content: "book" },
        /*{ label: "הוֹרד קבצי קורס", content: "book" },
         { label: "בחן את עצמיך בפיתוח", content: "book" },
        { label: "יעוץ ותמיכה", content: "book" },*/

        // ... Add the rest of your buttons here
    ];

    async function getLessonById(id) {
        try {
            const response =
                await FirebaseFirestoreRestService.readDocumentsById(
                    "lessons",
                    id
                );
            return response.lesson;
        } catch (error) {
            throw error;
        }
    }
    useEffect(() => {
        async function fetchData() {
            try {
                const id = courseObj?.courseModules[0]?.moduleLessons[0].id;
                if (id) {
                    const selectedLesson = await getLessonById(id);
                    setLesson(selectedLesson);
                    setModule(courseObj?.courseModules[0]);
                }
            } catch (error) {
                console.error(error.message);
            }
        }
        fetchData();
    }, [courseObj]);
    function scrollToPosition(y) {
        window.scrollTo({
            top: y,
            behavior: "smooth",
        });
    }
    const scrollToDiv = () => {
        if (myDivRef.current) {
            myDivRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    async function selectLesson(item, lesson, event) {
        const selectedLesson = await getLessonById(lesson.id);
        setLesson(selectedLesson);
        setModule(item);
        const viewportWidth = window.innerWidth / window.devicePixelRatio;
        if (viewportWidth < 450) {
            scrollToDiv();
        } else {
            scrollToPosition(0);
        }
    }
    function selectBook(contentType) {
        setSelectedContent(contentType);
    }

    function selectBook() {}
    return (
        <div className={styles.container}>
            {courseObj ? (
                <>
                    <div className={styles.tableHeader}>
                        <div className={styles.tableOfCOntent}>
                            <div className={styles.courseTitle}>
                                <h1>{courseObj?.title}</h1>
                            </div>
                            <div>
                                <img
                                    className={styles.imgCourse}
                                    src={courseObj?.page_image}
                                    alt="course"
                                />
                            </div>
                        </div>
                        <div className={styles.menu}>
                            <LessonsMenu
                                items={courseObj?.courseModules}
                                selectLesson={selectLesson}
                            />
                        </div>
                    </div>
                    {/* top header */}
                    <div className={styles.header}>
                        <LessonHeader
                            courseObj={courseObj}
                            lesson={lesson}
                            module={module}
                        />
                    </div>
                    <div className={styles.btns}>
                        {buttons.map((btn, index) => (
                            <button
                                key={index}
                                onClick={() => setSelectedContent(btn.content)}
                                className={styles.btn}
                            >
                                {btn.label}
                            </button>
                        ))}
                    </div>
                    {selectedContent === "video" && (
                        <div ref={myDivRef} className={styles.video}>
                            <HeaderVideo
                                selectedLesson={lesson}
                                course={courseObj}
                            />
                        </div>
                    )}
                    {selectedContent === "video" && (
                        <div className={styles.content}>
                            <OldCodeContent selectedLesson={lesson} />
                        </div>
                    )}
                    {selectedContent === "book" && (
                        <div className={styles.pdfBook}>
                            <BookPdf
                                books={books}
                                title={`ספרי קורס ${courseObj.title}`}
                            />
                        </div>
                    )}
                </>
            ) : (
                <div className={styles.loader}>{<Loader />}</div>
            )}
        </div>
    );
}
