import React, { useState } from "react";
import { FiChevronUp, FiChevronDown } from "react-icons/fi";
import styles from "./TableContent.module.css"; // Import your CSS Module file with styles

const TableContent = ({ children, title, subTitle, index }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={styles.accordionHeader} onClick={toggleAccordion}>
            <div className={styles.box}>
                <div className={styles.rightItem}>
                    <span
                        className={`${styles.accordionIcon} ${
                            isOpen ? styles.open : ""
                        }`}
                    ></span>
                    {isOpen ? <FiChevronDown /> : <FiChevronUp />}
                    <div className={styles.index}>{index}</div>
                    <div>{title}</div>
                </div>
                <div className={styles.subTitle}>{subTitle}</div>
            </div>

            <div className={styles.accordionContent}>
                {isOpen && <div className={styles.content}>{children}</div>}
            </div>
        </div>
    );
};

export default TableContent;
