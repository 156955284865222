import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FirebaseFirestoreRestService from "../../../services/FirebaseFireStoreRestService";
import Course from "../Course/Course"; // Import the Course component
import styles from "./Courses.module.css";
import Loader from "../../Tools/Loader/Loader";

export default function Courses() {
    const [items, setItems] = useState([]);
    // Autocomplete state and functions
    const [suggestions, setSuggestions] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const navigate = useNavigate();
    async function fetchData() {
        const queries = [];

        const orderByField = "order";
        let orderByDirection = "asc";
        try {
            const response = await FirebaseFirestoreRestService.readDocuments({
                collection: "courses",
                queries: queries,
                orderByField: orderByField,
                orderByDirection: orderByDirection,
            });
            //const arr = [response.courses[0], response.courses[1]];
            setItems(response.courses);
            setSuggestions(response.courses);
        } catch (error) {
            throw error;
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    const handleInputChange = (e) => {
        const value = e.target.value.toLowerCase();
        setInputValue(value);

        // Filter course titles based on user input
        const filteredCourses = items.filter((item) =>
            item.title.toLowerCase().includes(value)
        );
        setSuggestions(filteredCourses);
    };

    const handleSuggestionClick = (suggestion) => {
        // Handle the selection of a suggestion (e.g., set searchLanguage)
        navigate("/course/" + suggestion.id);
        setInputValue("");
    };

    return (
        <div className={styles.container}>
            {/* Autocomplete Input */}
            <div className={styles.div}>
                <input
                    type="text"
                    placeholder="❤️ יחד ננצח, חפש קורס... 🇮🇱"
                    value={inputValue}
                    onChange={handleInputChange}
                    className={styles.input}
                />
            </div>
            {suggestions.length > 0 ? (
                <div className={styles.courseGrid}>
                    {suggestions.map((item, index) => (
                        <div
                            className={styles.squere}
                            key={item.id + "" + index}
                            onClick={() => handleSuggestionClick(item)}
                        >
                            <Course course={item} />
                        </div>
                    ))}
                </div>
            ) : (
                <div className={styles.loader}>
                    <Loader />
                </div>
            )}
        </div>
    );
}
