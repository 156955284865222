import React, { useState } from "react";
import styles from "./Contact.module.css";
import { db } from "../../services/FirebaseConfig";
import { addDoc, collection } from "firebase/firestore";
import { Navigate } from "react-router-dom";
import { showNotification } from "../../util/notification";

export default function Contact() {
    const txt =
        "צרו קשר ונשמח לעזור. השאירו פרטים ונחזור אליכם. בגלל הגנת סייבר והודעות ספאם  השאירו הודעות בעברית בלבד, הודעות באנגלית נמחקות.   ";
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        message: "",
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const courseRef = collection(db, "contacts");
        await addDoc(courseRef, formData);
        showNotification("success", "ההודעה נשלחה בהצלחה");
        setFormData({
            name: "",
            email: "",
            phone: "",
            message: "",
        });
    };

    return (
        <div className={styles.container}>
            <h1>צור קשר</h1>
            <h3>{txt}</h3>
            <form className={styles.form} onSubmit={handleSubmit}>
                <label className={styles.label}>שם</label>
                <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    placeholder="שם"
                    required
                    className={styles.input}
                />
                <label className={styles.label}>אימייל</label>
                <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    placeholder="אימייל"
                    className={styles.input}
                    required
                />
                <label className={styles.label}>טלפון</label>
                <input
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    className={styles.input}
                    placeholder="מספר טלפון"
                />
                <label className={styles.label}>תוכן ההודעה</label>
                <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                    placeholder="תוכן ההודעה"
                    className={styles.input}
                    required
                ></textarea>
                <button className={styles.send} type="submit">
                    שלח
                </button>
            </form>
        </div>
    );
}
