import React, { useState } from "react";
import styles from "./Register.module.css";
import FirebaseAuthService from "../../../services/FirebaseAuthService";
import { showNotification } from "../../../util/notification";
import { Link, useNavigate } from "react-router-dom";
export default function Register() {
    const [firstName, setFirstName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    function onFirstNameChange(event) {
        setFirstName(event.target.value);
    }
    function onEmailChange(event) {
        setEmail(event.target.value);
    }
    function onPasswordChange(event) {
        setPassword(event.target.value);
    }
    async function createUser(event) {
        try {
            await FirebaseAuthService.registerUser(email, password, firstName);
            setEmail("");
            setPassword("");
            setFirstName("");
            navigate("/login");
        } catch (error) {
            showNotification("כשלון", "אנא וודא שמילאת שדות חוקיים");
            alert("אנא וודא שמילאת שדות חוקיים");
        }
    }
    return (
        <>
            <div className={styles.container}>
                <div className={styles.title}>צור חשבון</div>
                <p>ברוכים הבאים</p>
                <div className={styles.inputs}>
                    <input
                        type="text"
                        className={styles.inputText}
                        placeholder="שם מלא"
                        onChange={(e) => onFirstNameChange(e)}
                    />
                    <input
                        type="text"
                        className={styles.inputText}
                        placeholder="אימייל"
                        onChange={(e) => onEmailChange(e)}
                    />
                    <input
                        className={styles.inputText}
                        type="text"
                        placeholder="סיסמא"
                        onChange={(e) => onPasswordChange(e)}
                    />
                    <button onClick={createUser} className={styles.btnRegister}>
                        הרשם
                    </button>
                </div>
                <p>
                    יש לך כבר חשבון ? <Link to="/login">בצע לוגאין </Link>
                </p>
            </div>
        </>
    );
}
