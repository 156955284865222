const androidBooks = [
    {
        href: "/assets/moxifile/books/android/android1.pdf",
        title: "פרק 1-בניית האפליקציה הראשונה שלי",
        image: "https://media.discordapp.net/attachments/1144613859953479722/1165379228645216256/braintop_android_teaching_book_53507599-1f79-424c-815c-8bd0a3b31fcc.png?ex=6546a2fb&is=65342dfb&hm=9030ece682a038c040a87fd747ba34df62b8299001db203e9ceee24732f00354&=&width=580&height=580",
    },

    {
        href: "/assets/moxifile/books/android/android2.pdf",
        title: "פרק 2.סוגי האזנות בתכנות לאנדרואיד",
        image: "https://media.discordapp.net/attachments/1144613859953479722/1165380452161433751/braintop_android_teaching_book_aea1afb1-defc-453b-a5fd-f96f01f36955.png?ex=6546a41f&is=65342f1f&hm=28b7ec995b0390b0a3990501c07811ce61142e3d7055a3ba89df06691802bca6&=&width=580&height=580",
    },

    {
        href: "/assets/moxifile/books/android/android3.pdf",
        title: "פרק 3. סידורי מסך",
        image: "https://media.discordapp.net/attachments/1144613859953479722/1165380470326964254/braintop_android_teaching_book_8f3da944-ab4c-4442-88e1-4dfbf89a141a.png?ex=6546a423&is=65342f23&hm=25c8ef3f229ebdae01cc583bba68775ca922744c6b646aeaea09f3f682d7d243&=&width=580&height=580",
    },
    {
        href: "/assets/moxifile/books/android/android4.pdf",
        title: "פרק 4.תכנות דינאמי",
        image: "https://media.discordapp.net/attachments/1144613859953479722/1165380473917296690/braintop_android_teaching_book_fe88a8f5-ed5d-4d30-a47f-8c71f4feaf7c.png?ex=6546a424&is=65342f24&hm=1c7f07307b124704893d1a098316f004fa20bf9d196b06373bf27307c59069fc&=&width=580&height=580",
    },
    {
        href: "/assets/moxifile/books/android/android5.pdf",
        title: "פרק 5 - SharedPreferences",
        image: "https://media.discordapp.net/attachments/1144613859953479722/1165380965552627712/braintop_android_teaching_books_on_space_03137803-8cba-428c-a976-8efb0642ec43.png?ex=6546a499&is=65342f99&hm=43e64fea34879f368a7f2649d8361f462a34c07e801cdde33401ad4c386a2f1a&=&width=580&height=580",
    },
    {
        href: "/assets/moxifile/books/android/android6.pdf",
        title: "פרק 7 - Dialog",
        image: "https://media.discordapp.net/attachments/1144613859953479722/1165380975962898562/braintop_android_teaching_books_on_space_e5531415-6b88-4fd9-b0b5-395b5d60d9be.png?ex=6546a49c&is=65342f9c&hm=ee2c901783b8041ace203baa319796c9f95221fd85d85b1ef2cb437f7565f36c&=&width=580&height=580",
    },
    {
        href: "/assets/moxifile/books/android/android7.pdf",
        title: "פרק 7 - Dialog",
        image: "https://media.discordapp.net/attachments/1144613859953479722/1165380996728885258/braintop_android_teaching_books_on_space_a9c1d208-67fc-4bf8-93c4-ebfd7e369693.png?ex=6546a4a1&is=65342fa1&hm=b9b3a40365d9cec4a84e7d98c970bf80e41dfe4a3bdca76dfa34065547652b6f&=&width=580&height=580",
    },
    {
        href: "/assets/moxifile/books/android/android8.pdf",
        title: "פרק 8.תפריטים באנדרואיד",
        image: "https://media.discordapp.net/attachments/1144613859953479722/1165380996728885258/braintop_android_teaching_books_on_space_a9c1d208-67fc-4bf8-93c4-ebfd7e369693.png?ex=6546a4a1&is=65342fa1&hm=b9b3a40365d9cec4a84e7d98c970bf80e41dfe4a3bdca76dfa34065547652b6f&=&width=580&height=580",
    },
    {
        href: "/assets/moxifile/books/android/android9.pdf",
        title: "פרק9 .Intent מפורש",
        image: "https://media.discordapp.net/attachments/1144613859953479722/1165381000612810844/braintop_android_teaching_books_on_space_797d4063-ed98-4b9b-aeb2-5e1300e9db37.png?ex=6546a4a2&is=65342fa2&hm=4122411c594617fdcd16b4431a1d570313bff9546c324ba429e5acdbcd2471a6&=&width=580&height=580",
    },
    {
        href: "/assets/moxifile/books/android/android10.pdf",
        title: "פרק 10. עבודה מול Intent בצורה מרומזת",
        image: "https://media.discordapp.net/attachments/1144613859953479722/1165381482903248966/braintop_android_learn_book_on_city_fc615136-716e-4820-b2aa-0af779bd91f3.png?ex=6546a515&is=65343015&hm=720f5d8f351835310949926c5a3408b3f9b1651be0ff666ecc9073955e26d3b3&=&width=580&height=580",
    },
    {
        href: "/assets/moxifile/books/android/android11.pdf",
        title: "פרק 11.קבצים",
        image: "https://media.discordapp.net/attachments/1144613859953479722/1165381498170527887/braintop_android_learn_book_on_city_a98dab12-520e-4dc7-b154-840952a9f16b.png?ex=6546a518&is=65343018&hm=bf33246a87afe7c7c8a3d131437fed62a10db9c354198f577d4c24ea251b2105&=&width=580&height=580",
    },
    {
        href: "/assets/moxifile/books/android/android12.pdf",
        title: "פרק 12.זיכרון חיצוני באנדואיד",
        image: "https://media.discordapp.net/attachments/1144613859953479722/1165381523055321098/braintop_android_learn_book_on_city_26276735-6855-4bbd-9fa4-471ca7d01359.png?ex=6546a51e&is=6534301e&hm=5898e22c813bd1c93b1549c2d52a92bbc87a8b4f3b6939a7fe96b116bbc8bae2&=&width=580&height=580",
    },
    {
        href: "/assets/moxifile/books/android/android13.pdf",
        title: "פרק 13.סאונד ווידאו",
        image: "https://media.discordapp.net/attachments/1144613859953479722/1165381532442165268/braintop_android_learn_book_on_city_b1d13f66-1508-4ecc-b17e-0535bc7285ae.png?ex=6546a521&is=65343021&hm=79f21630825cdebe84b268723f0acaf92d53a2ff164d861906b2c46f202a055c&=&width=580&height=580",
    },
    {
        href: "/assets/moxifile/books/android/android14.pdf",
        title: "פרק 14.אנימציות",
        image: "https://media.discordapp.net/attachments/1144613859953479722/1165382045015474207/braintop_android_teach_and_learn_on_classroom_d8fd1f95-6bff-43f6-8795-bc0106f39559.png?ex=6546a59b&is=6534309b&hm=ff460f1faabcaaf5d33c9fb831631f058841553d0bb55787f99d43bbd8d0ec1a&=&width=580&height=580",
    },
    {
        href: "/assets/moxifile/books/android/android15.pdf",
        title: "פרק 15.ListView",
        image: "https://media.discordapp.net/attachments/1144613859953479722/1165382052372295770/braintop_android_teach_and_learn_on_classroom_cea4b587-2258-48ce-b1ed-2321be0965fe.png?ex=6546a59d&is=6534309d&hm=856016a37a22bab7cf14b39e3706e02466aa9663e42d357679c2796a1991238a&=&width=580&height=580",
    },
    {
        href: "/assets/moxifile/books/android/android16.pdf",
        title: "פרק 16.spinner ו- cardstack",
        image: "https://media.discordapp.net/attachments/1144613859953479722/1165382060848971806/braintop_android_teach_and_learn_on_classroom_e026ec55-c095-46e0-b6bc-049d2387d18c.png?ex=6546a59f&is=6534309f&hm=70da4a9b622201364b6f2b9b9181e0f09d8dcd8e71c07b5c5ca4829468940212&=&width=580&height=580",
    },
    {
        href: "/assets/moxifile/books/android/android17.pdf",
        title: "פרק 17.Thread",
        image: "https://media.discordapp.net/attachments/1144613859953479722/1165382078859325540/braintop_android_teach_and_learn_on_classroom_935c2e38-6d96-4669-bd48-bb84ce26f298.png?ex=6546a5a3&is=653430a3&hm=c611b3e249ab5195dfa0cf4e6228ee3cdbc0bd7c75051084d6877403808fad06&=&width=580&height=580",
    },
    {
        href: "/assets/moxifile/books/android/android18.pdf",
        title: "פרק 18.אנדרואיד Thread",
        image: "https://media.discordapp.net/attachments/1144613859953479722/1165382796647334108/braintop_android_learn_on_space_ship_20c81de6-ee17-47c3-9f75-b2d9738d583f.png?ex=6546a64e&is=6534314e&hm=d2b13eed088f7d308c0fbf7a20f42addd6fa73b86ce18c89d719ad56aa343d73&=&width=580&height=580",
    },
    {
        href: "/assets/moxifile/books/android/android19.pdf",
        title: "פרק 19.Service",
        image: "https://media.discordapp.net/attachments/1144613859953479722/1165382803517620274/braintop_android_learn_on_space_ship_375cecfd-cb74-4bb9-bada-7043b4833a7c.png?ex=6546a650&is=65343150&hm=5d52c7dcb40596a920740a79fd9f701e01cc3ff6b1dac2aa39bb24adc3b076c0&=&width=580&height=580",
    },
    {
        href: "/assets/moxifile/books/android/android20.pdf",
        title: "פרק 20.Broadcast Reciever",
        image: "https://media.discordapp.net/attachments/1144613859953479722/1165382811058978836/braintop_android_learn_on_space_ship_e772512d-89b7-4f41-8602-f0a51dddb849.png?ex=6546a651&is=65343151&hm=5c35dfd51641cc48ea042256dc965b79fbeaa070bd79e969d680d329b934e16b&=&width=580&height=580",
    },
    {
        href: "/assets/moxifile/books/android/android20b.pdf",
        title: "פרק 20ב. טלפוניה וברודכסט מתקדם",
        image: "https://media.discordapp.net/attachments/1144613859953479722/1165382831929823232/braintop_android_learn_on_space_ship_4d58e769-7a43-4132-a936-5ff6ab76ec40.png?ex=6546a656&is=65343156&hm=8c844e2cc72fc9addfedc5ba145bc7a940ea1f41188a2a91ad8dc52c29f2ce9b&=&width=580&height=580",
    },

    {
        href: "/assets/moxifile/books/android/android21.pdf",
        title: "פרק 21.חיישנים - Sensors",
        image: "https://media.discordapp.net/attachments/1144613859953479722/1165383283450847282/braintop_imagine_programing_android_appliction_a4c98376-06ae-4f4a-84bd-7b3e44691c50.png?ex=6546a6c2&is=653431c2&hm=1edd5561df16399f9d300f4bd17a7ba67dfd91cef47f129e12742737c8f99e0c&=&width=580&height=580",
    },
    {
        href: "/assets/moxifile/books/android/android22.pdf",
        title: "פרק 22.SQLITE",
        image: "https://media.discordapp.net/attachments/1144613859953479722/1165383291734605834/braintop_imagine_programing_android_appliction_729748d8-b1d5-4f40-961d-afa74a643d79.png?ex=6546a6c4&is=653431c4&hm=ca1d2b5109698bf2783fa40af19f6403ad19d1ec8fc646904ec4371a8f71cb13&=&width=580&height=580",
    },
    {
        href: "/assets/moxifile/books/android/android23.pdf",
        title: "פרק 23 .ContentProvider",
        image: "https://media.discordapp.net/attachments/1144613859953479722/1165383314404802631/braintop_imagine_programing_android_appliction_8626bff6-be04-4607-ae56-7c0718b7245d.png?ex=6546a6c9&is=653431c9&hm=41d87795fb0f3a610ada1901066003f46c8adcc5e4b7c007d2dd6677367a87bd&=&width=580&height=580",
    },
    {
        href: "/assets/moxifile/books/android/android23b.pdf",
        title: "פרק 23 ב.אנדרואיד view",
        image: "https://media.discordapp.net/attachments/1144613859953479722/1165383323745521785/braintop_imagine_programing_android_appliction_c20dd0af-6f71-47b7-993c-27f8d6954260.png?ex=6546a6cc&is=653431cc&hm=a205f91df8d7704f7217bc9cbd06e11ef08b29e8125188f6c09ddfe404353d36&=&width=580&height=580",
    },
];
const xamerinBooks = [
    {
        href: "/assets/moxifile/books/xamarin/xamarin1.pdf",
        title: "פרק 1-בניית האפליקציה הראשונה שלי",
        image: "https://media.discordapp.net/attachments/1144613859953479722/1165384383881019422/braintop_teaching_c_android_xamerin_on_space_2b1b3f43-1aab-490b-af08-72b1658d5396.png?ex=6546a7c8&is=653432c8&hm=100a3dec29890c8db8e903c9e5cf49afa23b0e44147bcef56e79f7666321665e&=&width=580&height=580",
    },

    {
        href: "/assets/moxifile/books/xamarin/xamarin2.pdf",
        title: "פרק 2.סוגי האזנות בתכנות לאנדרואיד",
        image: "https://media.discordapp.net/attachments/1144613859953479722/1165384393947365439/braintop_teaching_c_android_xamerin_on_space_c42d59bb-f731-432d-98b0-0e5d8bdf38d1.png?ex=6546a7cb&is=653432cb&hm=94ef3f0369688c171b261f75d84df28a86e3bc9ba10071c2ae9b9ba959645769&=&width=580&height=580",
    },

    {
        href: "/assets/moxifile/books/xamarin/xamarin3.pdf",
        title: "פרק 3. סידורי מסך",
        image: "https://media.discordapp.net/attachments/1144613859953479722/1165385214873305088/braintop_teach_and_learn_android_xamerin_c_microsoft_om_the_gar_11223c78-063a-4e25-9bb9-441a1fcf140c.png?ex=6546a88f&is=6534338f&hm=06541f7b9a0c330b99673b5b12a66df4361c5581c7ccaf22a626f168872d62d4&=&width=580&height=580",
    },
    {
        href: "/assets/moxifile/books/xamarin/xamarin4.pdf",
        title: "פרק 4.תכנות דינאמי",
        image: "https://media.discordapp.net/attachments/1144613859953479722/1165384417443856506/braintop_teaching_c_android_xamerin_on_space_5b34481e-5444-4a60-be7a-e5a3f04a7365.png?ex=6546a7d0&is=653432d0&hm=064d1f12b9b472e4358a951ad68c561f13f00feb55033da80af383f838daec0b&=&width=580&height=580",
    },
    {
        href: "/assets/moxifile/books/xamarin/xamarin5.pdf",
        title: "פרק 5 - SharedPreferences",
        image: "https://media.discordapp.net/attachments/1144613859953479722/1165384425819881502/braintop_teaching_c_android_xamerin_on_space_c5ba3dee-96ba-4c0f-962b-e4442236d150.png?ex=6546a7d2&is=653432d2&hm=0df21f1c86e9a0905dbfa803fc0367f5c989c276a3cf5ed5694d6f0b16e1405e&=&width=580&height=580",
    },
    {
        href: "assets/moxifile/books/xamarin/xamarin7.pdf",
        title: "פרק 7 - Dialog",
        image: "https://media.discordapp.net/attachments/1144613859953479722/1165387288247816212/braintop_old_people_teach_youn_people_progrming_android_java_af6204bf-1ec1-4c38-b411-3b10cd84ded7.png?ex=6546aa7d&is=6534357d&hm=b005b461ed89fd9bc27320fff9567d9fa88f1b02069b9cfee1d0aaf44a54dfeb&=&width=580&height=580",
    },
    {
        href: "/assets/moxifile/books/xamarin/xamarin8.pdf",
        title: "פרק 8.תפריטים באנדרואיד",
        image: "https://media.discordapp.net/attachments/1144613859953479722/1165385222376919070/braintop_teach_and_learn_android_xamerin_c_microsoft_om_the_gar_74ec7ebe-f76e-400d-ad07-0b1c76fe4a4c.png?ex=6546a890&is=65343390&hm=56cd83a24c717db602ef826efdf6c58ec63dbfe7aa5ccc3c88fa59ce3fd97d80&=&width=580&height=580",
    },
    {
        href: "/assets/moxifile/books/xamarin/xamarin9.pdf",
        title: "פרק9 .Intent מפורש",
        image: "https://media.discordapp.net/attachments/1144613859953479722/1165385231746998393/braintop_teach_and_learn_android_xamerin_c_microsoft_om_the_gar_09546684-f600-4dcc-9327-b6f6a8148e6d.png?ex=6546a893&is=65343393&hm=6fcc0019824b1cd0ac398913fdb86a3f8755dc079d378b9425cc96b094746b6f&=&width=580&height=580",
    },
    {
        href: "/assets/moxifile/books/xamarin/xamarin10.pdf",
        title: "פרק 10. עבודה מול Intent בצורה מרומזת",
        image: "https://media.discordapp.net/attachments/1144613859953479722/1165385258687012964/braintop_teach_and_learn_android_xamerin_c_microsoft_om_the_gar_14c86a9a-2242-4390-bced-f5432eefeabc.png?ex=6546a899&is=65343399&hm=a37636a049b929d1283b1bc4ab23c6617ff427f8a6cf26b30a7e312d3447fa9c&=&width=580&height=580",
    },
    {
        href: "/assets/moxifile/books/xamarin/xamarin11.pdf",
        title: "פרק 11.קבצים",
        image: "https://media.discordapp.net/attachments/1144613859953479722/1165385699655168020/braintop_teach_and_learn_android_xamerin_c_microsoft_om_the_see_cdbf2417-2af6-4399-9a8d-4635c09fa601.png?ex=6546a902&is=65343402&hm=bfc9db335f1c495dbee829cd23abbdf247edc792676499a2b76d6b4601c2e6fe&=&width=580&height=580",
    },
    {
        href: "/assets/moxifile/books/xamarin/xamarin12.pdf",
        title: "פרק 12.זיכרון חיצוני באנדואיד",
        image: "https://media.discordapp.net/attachments/1144613859953479722/1165385691384008795/braintop_teach_and_learn_android_xamerin_c_microsoft_om_the_see_443dee3c-16ad-4538-971f-74f55d4ffb59.png?ex=6546a900&is=65343400&hm=19195ea30a43d21bfdc262e140343bbee739b9201572d5f1a32f3be95027242c&=&width=580&height=580",
    },
    {
        href: "/assets/moxifile/books/xamarin/xamarin13.pdf",
        title: "פרק 13.סאונד ווידאו",
        image: "https://media.discordapp.net/attachments/1144613859953479722/1165385706852597780/braintop_teach_and_learn_android_xamerin_c_microsoft_om_the_see_505f89f1-c109-439a-8cfa-9f133c6f5fb6.png?ex=6546a904&is=65343404&hm=b8b8f9e9e1c3d5472741ad57f91b7f2a2b31aad1e7d9507e9c7aab8a0c8e9d1c&=&width=580&height=580",
    },
    {
        href: "/assets/moxifile/books/xamarin/xamarin14.pdf",
        title: "פרק 14.אנימציות",
        image: "https://media.discordapp.net/attachments/1144613859953479722/1165385730047099022/braintop_teach_and_learn_android_xamerin_c_microsoft_om_the_see_eb95366b-32ae-467e-b296-5a8a58efb7f3.png?ex=6546a909&is=65343409&hm=81cd7747d50f56b193d65f88bec7c30b451419b5a7d21fb863eeadd917da28ea&=&width=580&height=580",
    },
    {
        href: "/assets/moxifile/books/xamarin/xamarin15.pdf",
        title: "פרק 15.ListView",
        image: "https://media.discordapp.net/attachments/1144613859953479722/1165386164467945514/braintop_teach_android_xamerin_x_microsoft_for_herro_and_brave__665bc28c-7916-4483-af6c-4cf5a4e3d130.png?ex=6546a971&is=65343471&hm=c930e9f613316377e4f33b06f9606c57d6259c3dcc7954b621b1b369b7be7a54&=&width=580&height=580",
    },
    {
        href: "/assets/moxifile/books/xamarin/xamarin16.pdf",
        title: "פרק 16.spinner ו- cardstack",
        image: "https://media.discordapp.net/attachments/1144613859953479722/1165386173053681684/braintop_teach_android_xamerin_x_microsoft_for_herro_and_brave__9c18dd1e-73e1-479a-934a-68f2400bd44d.png?ex=6546a973&is=65343473&hm=64c71b5aafa4f1e9f58e4f0b702e381299e92c2be046baf9f1f36e120a5375dd&=&width=580&height=580",
    },
    {
        href: "/assets/moxifile/books/xamarin/xamarin17.pdf",
        title: "פרק 17.Thread",
        image: "https://media.discordapp.net/attachments/1144613859953479722/1165386180901216278/braintop_teach_android_xamerin_x_microsoft_for_herro_and_brave__30b3f197-24e3-4f78-9a43-3f1a4b398e7d.png?ex=6546a975&is=65343475&hm=e858e533a796c45e8f92d0ca2be7e03bb51876398bb065b186867b0ade759cc2&=&width=580&height=580",
    },
    {
        href: "/assets/moxifile/books/xamarin/xamarin18.pdf",
        title: "פרק 18.אנדרואיד Thread",
        image: "https://media.discordapp.net/attachments/1144613859953479722/1165386200132096142/braintop_teach_android_xamerin_x_microsoft_for_herro_and_brave__8fce1c11-79cc-4ca6-a97f-65e2a70bb6ab.png?ex=6546a979&is=65343479&hm=89c2b41dac5c9c4cb9c9ac40b1b1384ad8eda53fa82c6cc22249862afe0b61b2&=&width=580&height=580",
    },
    {
        href: "/assets/moxifile/books/xamarin/xamarin19.pdf",
        title: "פרק 19.Service",
        image: "https://media.discordapp.net/attachments/1144613859953479722/1165386737086889994/braintop_A_real_princess_learns_to_develop_Android_apps_1cba9457-9469-4cc7-83b7-b1025f25aef6.png?ex=6546a9f9&is=653434f9&hm=df4991cfcaf5843789dc5371a2146fd231d4f618bfb001d2bdda91f0eff9c961&=&width=580&height=580",
    },
    {
        href: "/assets/moxifile/books/xamarin/xamarin20.pdf",
        title: "פרק 20.Broadcast Reciever",
        image: "https://media.discordapp.net/attachments/1144613859953479722/1165386745823633528/braintop_A_real_princess_learns_to_develop_Android_apps_2f8bbd3c-ecb3-442f-b490-67d5a5efbd43.png?ex=6546a9fc&is=653434fc&hm=3e95a856aac488db43e2f929dee8ac8d2b996fcee9d27fe7e74736a36ddef212&=&width=580&height=580",
    },
    {
        href: "/assets/moxifile/books/xamarin/xamarin20b.pdf",
        title: "פרק 20ב. טלפוניה וברודכסט מתקדם",
        image: "https://media.discordapp.net/attachments/1144613859953479722/1165386752714879148/braintop_A_real_princess_learns_to_develop_Android_apps_9f6f8c4f-ca0a-456f-91b5-c3004faa65ab.png?ex=6546a9fd&is=653434fd&hm=9e284b9d7fc5d9ba8ea438cf6a075385443be35b0b6d9d00d0f56c14511b7cfe&=&width=580&height=580",
    },
    {
        href: "/assets/moxifile/books/xamarin/xamarin21.pdf",
        title: "פרק 21.חיישנים - Sensors",
        image: "https://media.discordapp.net/attachments/1144613859953479722/1165387235378614462/braintop_old_people_teach_youn_people_progrming_android_java_80081484-9b17-4133-8540-6df02b5dc6c7.png?ex=6546aa70&is=65343570&hm=8e84d8bbff56d22381df441ef7c263cb1e134a40aaf603ea88e216662a1eda56&=&width=580&height=580",
    },
    {
        href: "/assets/moxifile/books/xamarin/xamarin23b.pdf",
        title: "פרק 23 .canvas view",
        image: "https://media.discordapp.net/attachments/1144613859953479722/1165387244731895860/braintop_old_people_teach_youn_people_progrming_android_java_19c147e8-27cb-44b8-8fa7-ea72f8dfa3ef.png?ex=6546aa72&is=65343572&hm=c6f78ef56b6a62adb60580c7d621fb50b2f8df4be42e81e7d8a352ee8c884d07&=&width=580&height=580",
    },
    {
        href: "/assets/moxifile/books/xamarin/xamarin23c.pdf",
        title: "פרק 23 ב.אנדרואיד surface view",
        image: "https://media.discordapp.net/attachments/1144613859953479722/1165386767336214702/braintop_A_real_princess_learns_to_develop_Android_apps_f49973a1-d890-4039-b879-e684383bd11a.png?ex=6546aa01&is=65343501&hm=1035da69a20965d23c25a22f105d2763dbb84d47dd1c5473853303204f836ac4&=&width=580&height=580",
    },
];
export { androidBooks, xamerinBooks };
